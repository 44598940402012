export const convertFormulaToExplanation = (formula: string, startDateString: string, repeatFrequencyId: number, repeatFrequency: string) => {
    if (formula == undefined) { return ''; }
    if (repeatFrequencyId !== null) {
        return `${repeatFrequency}`;
    }
    const startDate = new Date(startDateString);
    const regex = /(\+|\-)\d+(d|w|m|y)|(first|last)dayofweek|\d(first|second|third|fourth|last)ofmonth/g;
    let string = 'Occurs ';
    const matches = formula.match(regex);
    if (matches == null || matches == undefined) { return; }
    const number = matches[0].match(/\d+/)![0];
    if (matches.length == 1) {
        if (number == undefined) { return; }
        if (matches[0].includes('d')) {
            if (number == '1') {
                string += 'every day';
                return string;
            } else if (number == '2') {
                string += 'every other day';
                return string;
            } else {
                string += `every ${number} days`;
                return string;
            }
        } else if (matches[0].includes('w')) {
            if (number == '1') {
                string += `every ${getDayName(startDate.getDay())}`;
                return string;
            } else if (number == '2') {
                string += `every other week on ${getDayName(startDate.getDay())}`;
                return string;
            } else {
                string += `every ${number} weeks on ${getDayName(startDate.getDay())}`;
                return string;
            }
        } else if (matches[0].includes('m')) {
            if (number == '1') {
                string += `every month on day ${startDate.getDate()}`;
                return string;
            } else if (number == '2') {
                string += `every other month on day ${startDate.getDate()}`;
                return string;
            } else {
                string += `every ${number} months on day ${startDate.getDate()}`;
                return string;
            }
        } else if (matches[0].includes('y')) {
            if (number == '1') {
                string += `every year on ${startDate.toLocaleString('default', { month: 'long' })} ${startDate.getDate()}`;
                return string;
            } else if (number == '2') {
                string += `every other year on ${startDate.toLocaleString('default', { month: 'long' })} ${startDate.getDate()}`;
                return string;
            } else {
                string += `every ${number} years on ${startDate.toLocaleString('default', { month: 'long' })} ${startDate.getDate()}`;
                return string;
            }
        } else {
            return '';
        }
    } else {
        if (matches[0].includes('m')) {
            if (number == '1') {
                string += `every month on `;
            } else if (number == '2') {
                string += `every other month on `;
            } else {
                string += `every ${number} months on `;
            }
            if (matches[1].includes('firstofmonth')) {
                string += `the first ${getDayName(startDate.getDay())}`;
                return string;
            } else if (matches[1].includes('secondofmonth')) {
                string += `the second ${getDayName(startDate.getDay())}`;
                return string;
            } else if (matches[1].includes('thirdofmonth')) {
                string += `the third ${getDayName(startDate.getDay())}`;
                return string;
            } else if (matches[1].includes('fourthofmonth')) {
                string += `the fourth ${getDayName(startDate.getDay())}`;
                return string;
            } else if (matches[1].includes('lastofmonth')) {
                string += `the last ${getDayName(startDate.getDay())}`;
                return string;
            } else {
                return '';
            }
        } else if (matches[0].includes('y')) {
            if (number == '1') {
                string += `every year on `;
            } else if (number == '2') {
                string += `every other year on `;
            } else {
                string += `every ${number} years on `;
            }
            if (matches[1].includes('firstofmonth')) {
                string += `the first ${getDayName(startDate.getDay())} of ${startDate.toLocaleString('default', { month: 'long' })}`;
                return string;
            } else if (matches[1].includes('secondofmonth')) {
                string += `the second ${getDayName(startDate.getDay())} of ${startDate.toLocaleString('default', { month: 'long' })}`;
                return string;
            } else if (matches[1].includes('thirdofmonth')) {
                string += `the third ${getDayName(startDate.getDay())} of ${startDate.toLocaleString('default', { month: 'long' })}`;
                return string;
            } else if (matches[1].includes('fourthofmonth')) {
                string += `the fourth ${getDayName(startDate.getDay())} of ${startDate.toLocaleString('default', { month: 'long' })}`;
                return string;
            } else if (matches[1].includes('lastofmonth')) {
                string += `the last ${getDayName(startDate.getDay())} of ${startDate.toLocaleString('default', { month: 'long' })}`;
                return string;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }
}

export const getSelectedOptions = (formula: string, startDate: string): OptionObject | undefined => {
    if (formula == null || formula == undefined) { return; }
    const regex = /(\+|\-)\d+(d|w|m|y)|(first|last)dayofweek|\d(first|second|third|fourth|last)ofmonth/g;
    const matches = formula.match(regex);
    if (matches == null || matches == undefined) { return; }
    const numberMatch = matches[0].match(/\d+/)![0];
    if (matches.length == 1) {
        if (numberMatch == undefined) { return; }
        if (matches[0].includes('d')) {
            if (numberMatch == '1') {
                return {
                    number: 1,
                    option: "day",
                    selectedDay: [...days],
                    selectedValueMonthIndex: null,
                    renderDaySelector: true,
                    renderRadioSelector: false
                };
            } else if (numberMatch == '2') {
                return {
                    number: 2,
                    option: "days",
                    selectedDay: null,
                    selectedValueMonthIndex: null,
                    renderDaySelector: true,
                    renderRadioSelector: false
                };
            } else {
                return {
                    number: parseInt(numberMatch),
                    option: "days",
                    selectedDay: null,
                    selectedValueMonthIndex: null,
                    renderDaySelector: true,
                    renderRadioSelector: false
                };
            }
        } else if (matches[0].includes('w')) {
            if (numberMatch == '1') {
                return {
                    number: 1,
                    option: "week",
                    selectedDay: null,
                    selectedValueMonthIndex: null,
                    renderDaySelector: true,
                    renderRadioSelector: false
                };
            } else if (numberMatch == '2') {
                return {
                    number: 2,
                    option: "weeks",
                    selectedDay: null,
                    selectedValueMonthIndex: null,
                    renderDaySelector: true,
                    renderRadioSelector: false
                };
            } else {
                return {
                    number: parseInt(numberMatch),
                    option: "weeks",
                    selectedDay: null,
                    selectedValueMonthIndex: null,
                    renderDaySelector: true,
                    renderRadioSelector: false
                };
            }
        } else if (matches[0].includes('m')) {
            if (numberMatch == '1') {
                return {
                    number: 1,
                    option: "month",
                    selectedDay: null,
                    selectedValueMonthIndex: 0,
                    renderDaySelector: false,
                    renderRadioSelector: true
                };
            } else if (numberMatch == '2') {
                return {
                    number: 2,
                    option: "months",
                    selectedDay: null,
                    selectedValueMonthIndex: 0,
                    renderDaySelector: false,
                    renderRadioSelector: true
                };
            } else {
                return {
                    number: parseInt(numberMatch),
                    option: "months",
                    selectedDay: null,
                    selectedValueMonthIndex: 0,
                    renderDaySelector: false,
                    renderRadioSelector: true
                };
            }
        } else if (matches[0].includes('y')) {
            if (numberMatch == '1') {
                return {
                    number: 1,
                    option: "year",
                    selectedDay: null,
                    selectedValueMonthIndex: 0,
                    renderDaySelector: false,
                    renderRadioSelector: true
                };
            } else if (numberMatch == '2') {
                return {
                    number: 2,
                    option: "years",
                    selectedDay: null,
                    selectedValueMonthIndex: 0,
                    renderDaySelector: false,
                    renderRadioSelector: true
                };
            } else {
                return {
                    number: parseInt(numberMatch),
                    option: "years",
                    selectedDay: null,
                    selectedValueMonthIndex: 0,
                    renderDaySelector: false,
                    renderRadioSelector: true
                };
            }
        } else {
            return;
        }
    } else {
        if (matches[0].includes('m')) {
            const options = {
                number: 0,
                option: "month",
                selectedDay: null,
                selectedValueMonthIndex: 0,
                renderDaySelector: false,
                renderRadioSelector: true
            };

            if (numberMatch == '1') {
                options.number = 1;
            } else if (numberMatch == '2') {
                options.number = 2;
                options.option = "months"
            } else {
                options.number = parseInt(numberMatch);
                options.option = "months"
            }

            const occurrence = getOccuranceNumber(startDate);
            if (occurrence == 4) {
                if (matches[1].includes('firstofmonth') || matches[1].includes('secondofmonth') || matches[1].includes('thirdofmonth') || matches[1].includes('fourthofmonth')) {
                    options.selectedValueMonthIndex = 1;
                } else if (matches[1].includes('lastofmonth')) {
                    options.selectedValueMonthIndex = 2;
                } else {
                    return;
                }
            } else {
                const weekdayOccurrence = getWeekdayOccurrence(startDate);
                if (weekdayOccurrence == 4) {
                    if (matches[1].includes('fourthofmonth')) {
                        options.selectedValueMonthIndex = 1;
                    } else {
                        options.selectedValueMonthIndex = 2;
                    }
                } else {
                    options.selectedValueMonthIndex = 1;
                }
            }
            return options;
        } else if (matches[0].includes('y')) {
            const options = {
                number: 0,
                option: "year",
                selectedDay: null,
                selectedValueMonthIndex: 0,
                renderDaySelector: false,
                renderRadioSelector: true
            };

            if (numberMatch == '1') {
                options.number = 1;
            } else if (numberMatch == '2') {
                options.number = 2;
                options.option = "years"
            } else {
                options.number = parseInt(numberMatch);
                options.option = "years"
            }

            const occurrence = getOccuranceNumber(startDate);
            if (occurrence == 4) {
                if (matches[1].includes('firstofmonth') || matches[1].includes('secondofmonth') || matches[1].includes('thirdofmonth') || matches[1].includes('fourthofmonth')) {
                    options.selectedValueMonthIndex = 1;
                } else if (matches[1].includes('lastofmonth')) {
                    options.selectedValueMonthIndex = 2;
                } else {
                    return;
                }
            } else {
                const weekdayOccurrence = getWeekdayOccurrence(startDate);
                if (weekdayOccurrence == 4) {
                    if (matches[1].includes('fourthofmonth')) {
                        options.selectedValueMonthIndex = 1;
                    } else {
                        options.selectedValueMonthIndex = 2;
                    }
                } else {
                    options.selectedValueMonthIndex = 1;
                }
            }
            return options;
        } else {
            return;
        }
    }
}

const getOccuranceNumber = (startDate: string) => {
    const date = new Date(startDate);
    const dayOfWeek = date.getDay();
    const dayOfMonth = date.getDate();

    const firstOfMonthDayOfWeek = new Date(date.getFullYear(), date.getMonth(), 1).getDay();

    let offset = (dayOfWeek + 7 - firstOfMonthDayOfWeek) % 7;
    if (offset === 0) offset = 7;

    const occurrence = Math.floor((dayOfMonth - 1) / 7) + 1 - Math.floor((offset - 1) / 7);
    return occurrence;
}

function getWeekdayOccurrence(dateString: string) {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();

    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstWeekday = firstDayOfMonth.getDay();

    let offset = dayOfWeek - firstWeekday;
    if (offset < 0) {
        offset += 7;
    }

    const daysFromFirstWeekday = date.getDate() - 1 - offset;

    const occurrence = Math.floor(daysFromFirstWeekday / 7) + 1;

    return occurrence;
}

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const getDayName = (number: number) => {
    switch (number) {
        case 0:
            return 'Sunday';
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        default:
            return '';
    }
}

interface OptionObject {
    number: number | null,
    option: string | null,
    selectedDay: string[] | null,
    selectedValueMonthIndex: number | null,
    renderDaySelector: boolean,
    renderRadioSelector: boolean
}